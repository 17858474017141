import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './PatientForm.module.scss';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import ErrorBox from '../../components/ErrorBox/ErrorBox';
import FileDropper from '../../components/FileDropper/FileDropper';

// const options = ['Dairy', 'Eggs', 'Tree Nuts', 'Peanuts', 'Soy', 'Wheat', 'Fish', 'Seeds'];

const customStyles = {
  medicalCaseContainer: {
    paddingLeft: 15,
    paddingRight: 15
  },
  inputs: {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15
  },
  initialInput: {
    flex: 0.5,
    paddingLeft: 15,
    paddingRight: 15
  },
  spinner: {
    marginLeft: 15
  }
};
class PatientForm extends PureComponent {
  constructor(props) {
    super(props);

    const { user, selectedCase = {} } = props;
    this.state = {
      medicalCaseNo: selectedCase.medical_case_no || '',
      firstName: user.first_name || '',
      lastName: user.last_name || '',
      middleInitial: user.middle_name || '',
      dateOfBirth: user.date_of_birth || moment(),
      gender: user.gender || '',
      email: user.email || '',
      foodTherapyPlan: selectedCase.therapy_plan || '',
      phone: user.phone || '',
      // anaphylaxis: user.anaphylaxis || [],
      anaphylaxisFile: selectedCase.anaphylaxis_file || null,
      foodImmunologyFile: selectedCase.immunology_file || null,
      foodDosingFile: selectedCase.food_dosing_file || null
    };
  }

  onChangeText = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  onClickAddNewCase = () => {
    console.log(this.state);
    const { onSubmit } = this.props;
    const {
      medicalCaseNo,
      firstName,
      middleInitial,
      lastName,
      dateOfBirth,
      gender,
      email,
      phone,
      foodTherapyPlan,
      foodImmunologyFile,
      foodDosingFile,
      anaphylaxisFile
    } = this.state;

    const data = {
      medical_case_no: medicalCaseNo,
      first_name: firstName,
      last_name: lastName,
      middle_name: middleInitial,
      date_of_birth: dateOfBirth,
      gender,
      email,
      phone,
      therapy_plan: foodTherapyPlan,
      immunology_file: foodImmunologyFile,
      anaphylaxis_file: anaphylaxisFile,
      food_dosing_file: foodDosingFile
    };

    onSubmit(data);
  };

  onClickUpdate = () => {
    console.log(this.state);
    const { onSubmit, user, selectedCase } = this.props;
    const {
      medicalCaseNo,
      firstName,
      middleInitial,
      lastName,
      dateOfBirth,
      gender,
      email,
      phone,
      foodTherapyPlan,
      foodImmunologyFile,
      foodDosingFile,
      anaphylaxisFile
    } = this.state;

    const updatedUser = {
      first_name: firstName,
      last_name: lastName,
      middle_name: middleInitial,
      date_of_birth: dateOfBirth,
      gender,
      email,
      phone
    };

    const updatedCase = {
      id: selectedCase.id,
      medical_case_no: medicalCaseNo,
      therapy_plan: foodTherapyPlan,
      immunology_file: foodImmunologyFile,
      anaphylaxis_file: anaphylaxisFile,
      food_dosing_file: foodDosingFile
    };

    const data = {
      updatedUser,
      updatedCase,
      userId: user.id
    };
    onSubmit(data);
  };

  approveCase = () => {
    const { onStatusUpdate, selectedCase } = this.props;
    const data = {
      status: 'APPROVED',
      id: selectedCase.id
    };
    onStatusUpdate(data);
  };

  remissionCase = () => {
    const { onStatusUpdate, selectedCase } = this.props;
    const data = {
      status: 'REMISSION',
      id: selectedCase.id
    };

    onStatusUpdate(data);
  };

  setAnaphylaxis = e => {
    const { anaphylaxis } = this.state;
    const { name } = e.target;
    let newOptions = anaphylaxis.slice();
    if (newOptions.indexOf(name) > -1) {
      newOptions = newOptions.filter(option => option !== name);
    } else {
      newOptions.push(name);
    }

    this.setState({
      anaphylaxis: newOptions
    });
  };

  handleFileUpload = (file, name) => {
    this.setState({ [name]: file });
  };

  render() {
    const {
      medicalCaseNo,
      firstName,
      lastName,
      middleInitial,
      dateOfBirth,
      gender,
      email,
      foodTherapyPlan,
      phone,
      // anaphylaxis,
      anaphylaxisFile,
      foodImmunologyFile,
      foodDosingFile
    } = this.state;

    const { user, success, loading, error, auth, selectedCase = {} } = this.props;
    const isUpdate = Object.keys(user).length > 0;

    return (
      <div className="container">
        <div className="page-header">
          <h1>{isUpdate ? 'Update Patient' : 'Patient Registration'}</h1>
        </div>

        <div className={styles.inputsContainer}>
          <Input
            type="text"
            onChange={this.onChangeText}
            value={medicalCaseNo}
            name="medicalCaseNo"
            placeholder="Medical Case #"
            label="Medical Case #"
            containerStyle={customStyles.medicalCaseContainer}
          />
        </div>

        <div className={styles.inputsContainer}>
          <Input
            type="text"
            onChange={this.onChangeText}
            value={firstName}
            name="firstName"
            placeholder="Patient First Name"
            label="First Name"
            containerStyle={customStyles.inputs}
          />
          <Input
            type="text"
            onChange={this.onChangeText}
            value={middleInitial}
            name="middleInitial"
            placeholder="M.I."
            label="M.I."
            containerStyle={customStyles.initialInput}
          />

          <Input
            type="text"
            onChange={this.onChangeText}
            value={lastName}
            name="lastName"
            placeholder="Patient Last Name"
            label="Last Name"
            containerStyle={customStyles.inputs}
          />
        </div>

        <div className={styles.inputsContainer}>
          <Input
            type="date"
            onChange={this.onChangeText}
            value={moment(dateOfBirth)
              .utcOffset(0)
              .format('YYYY-MM-DD')}
            name="dateOfBirth"
            placeholder="mm/dd/yyyy"
            label="Date Of Birth"
            containerStyle={customStyles.inputs}
          />

          <div className={styles.genderContainer}>
            <p className={styles.label}>GENDER</p>
            <div className={styles.genderInputs}>
              <input
                type="radio"
                onChange={this.onChangeText}
                value={'male'}
                name="gender"
                checked={gender === 'male'}
                label="Gender"
              />
              <p>Male</p>
              <input
                type="radio"
                onChange={this.onChangeText}
                checked={gender === 'female'}
                value={'female'}
                name="gender"
              />
              <p>Female</p>
            </div>
          </div>
        </div>

        <div className={styles.inputsContainer}>
          <Input
            type="text"
            onChange={this.onChangeText}
            value={email}
            name="email"
            placeholder="Email"
            label="Email"
            containerStyle={customStyles.inputs}
          />

          <Input
            type="number"
            onChange={this.onChangeText}
            value={phone}
            name="phone"
            placeholder="Phone"
            label="Phone"
            containerStyle={customStyles.inputs}
          />
        </div>

        <hr />

        <div className={styles.foodTherapyPlanContainer}>
          <p className={styles.label}>CURRENT FOOD THERAPY PLAN</p>
          <textarea
            name="foodTherapyPlan"
            className={styles.foodTherapyTextArea}
            value={foodTherapyPlan}
            onChange={this.onChangeText}
          />
        </div>

        {/* <div className={styles.anaphylaxisContainer}>
          <p className={styles.label}>ANAPHYLAXIS</p>
          <div className={styles.anaphylaxisOptions}>
            {options.map(option => {
              const isChecked = anaphylaxis.indexOf(option) > -1;
              return (
                <div key={option} className={styles.optionsContainer}>
                  <input
                    type="checkbox"
                    onChange={this.setAnaphylaxis}
                    checked={isChecked}
                    name={option}
                  />
                  <p>{option}</p>
                </div>
              );
            })}
          </div>
        </div> */}

        <div className={styles.uploadsContainer}>
          <p className={styles.label}>PATIENT PDF UPLOADS</p>
          <div className={styles.uploadBoxesContainer}>
            <div className={styles.uploadBox}>
              <p className={styles.label}>Food Immunology</p>
              <FileDropper
                accepts="PDF or Image"
                file={foodImmunologyFile}
                type={'foodImmunologyFile'}
                name="Food Immunology"
                onFileDropped={this.handleFileUpload}
              />
            </div>
            <div className={styles.uploadBox}>
              <p className={styles.label}>Anaphylaxis</p>
              <FileDropper
                accepts="PDF or Image"
                file={anaphylaxisFile}
                type={'anaphylaxisFile'}
                name="Anaphylaxis"
                onFileDropped={this.handleFileUpload}
              />
            </div>

            <div className={styles.uploadBox}>
              <p className={styles.label}>Food Dosing Strategy</p>
              <FileDropper
                accepts="PDF or Image"
                file={foodDosingFile}
                type={'foodDosingFile'}
                name="Food Dosing Strategy"
                onFileDropped={this.handleFileUpload}
              />
            </div>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          {auth.user.role === 'super' && (
            <div>
              {selectedCase.status === 'WAITING_FOR_APPROVAL' && (
                <Button
                  title="Approve"
                  style={{ backgroundColor: '#28a745' }}
                  onClick={this.approveCase}
                  disabled={loading}
                />
              )}
              {selectedCase.status !== 'REMISSION' && (
                <Button
                  title="Remission"
                  style={{ marginLeft: 15, marginRight: 15, backgroundColor: 'red' }}
                  onClick={this.remissionCase}
                  disabled={loading}
                />
              )}
            </div>
          )}
          <Button
            title={isUpdate ? 'Update Case' : 'Add New Case'}
            onClick={isUpdate ? this.onClickUpdate : this.onClickAddNewCase}
            disabled={loading}
          />
          {error && <ErrorBox error={error} />}
          {success && (
            <p>{isUpdate ? 'Update Successful!' : 'New Case Created! Redirecting to Cases.'}</p>
          )}
          {loading && (
            <Spinner fadeIn="none" name="circle" color="#4c6072" style={customStyles.spinner} />
          )}
        </div>
      </div>
    );
  }
}

PatientForm.propTypes = {
  user: PropTypes.object,
  selectedCase: PropTypes.object,
  error: PropTypes.string,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  onSubmit: PropTypes.func,
  onStatusUpdate: PropTypes.func,
  auth: PropTypes.object
};

PatientForm.defaultProps = {
  user: {},
  error: undefined,
  loading: false,
  success: false,
  redirect: false
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PatientForm);
