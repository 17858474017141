import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import styles from './UpdatePatientCase.module.scss';
import NavBar from '../../containers/NavBar/NavBar';
import PatientForm from '../../containers/PatientForm/PatientForm';
import Cases from '../../redux/actions/Cases';
import User from '../../redux/actions/User';

class UpdatePatient extends PureComponent {
  state = {
    selectedCase: null,
    user: null,
    success: false,
    loading: false,
    error: undefined
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { state: pushedStated } = this.props.location;
    try {
      const caseResult = await Cases.getCaseByUserId(pushedStated.caseSelected.user_id);
      const userResult = await User.getUserById(pushedStated.caseSelected.user_id);
      if (caseResult && userResult) {
        this.setState({ selectedCase: caseResult, user: userResult });
      }
    } catch (error) {
      console.log(error);
    }
  };

  updateCase = async data => {
    this.setState({ success: false, error: undefined, loading: true });

    const { updatedUser, updatedCase, userId } = data;

    const uploadData = { ...updatedCase };

    try {
      if (uploadData.immunology_file !== null && typeof uploadData.immunology_file === 'object') {
        const foodImmunologyUrl = await Cases.uploadFile(uploadData.immunology_file);
        uploadData.immunology_file = foodImmunologyUrl.imageUrl;
      }

      if (uploadData.food_dosing_file !== null && typeof uploadData.food_dosing_file === 'object') {
        const foodDosingUrl = await Cases.uploadFile(uploadData.food_dosing_file);
        uploadData.food_dosing_file = foodDosingUrl.imageUrl;
      }

      if (uploadData.anaphylaxis_file !== null && typeof uploadData.anaphylaxis_file === 'object') {
        const anaphylaxisUrl = await Cases.uploadFile(uploadData.anaphylaxis_file);
        uploadData.anaphylaxis_file = anaphylaxisUrl.imageUrl;
      }

      const updateUserResult = await User.updateUserById(userId, updatedUser);
      const updateCaseResult = await Cases.updateCaseById(uploadData);
      if (updateUserResult && updateCaseResult) {
        this.setState({ success: true, error: undefined, loading: false });
      }
    } catch (error) {
      this.setState({ success: false, error: error.message, loading: false });
      console.log(error);
    }
  };

  updateCaseStatus = async data => {
    this.setState({ success: false, error: undefined, loading: true });

    try {
      const updateCaseResult = await Cases.updateCaseById(data);
      if (updateCaseResult) {
        this.setState({
          selectedCase: updateCaseResult,
          success: true,
          error: undefined,
          loading: false
        });
      }
    } catch (error) {
      this.setState({ success: false, error: error.message, loading: false });
      console.log(error);
    }
  };

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { user, selectedCase, success, loading, error } = this.state;
    const { state: pushedStated } = this.props.location;

    if (!pushedStated || !pushedStated.caseSelected) {
      return <Redirect to="/cases/all" />;
    }
    return (
      <div className={styles.all}>
        <NavBar />
        <div className={styles.backBar}>
          <div className="container">
            <FontAwesomeIcon icon={faChevronLeft} size="sm" color="#4c6072" />
            <p onClick={this.goBack}>Back to Inbox</p>
          </div>
        </div>
        {(selectedCase !== null || user !== null) && (
          <PatientForm
            onStatusUpdate={this.updateCaseStatus}
            onSubmit={this.updateCase}
            success={success}
            error={error}
            loading={loading}
            selectedCase={selectedCase}
            user={user}
          />
        )}
      </div>
    );
  }
}

UpdatePatient.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};

export default UpdatePatient;
