import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

class Button extends PureComponent {
  render() {
    const { style, title, color, onClick, disabled } = this.props;
    return (
      <button
        className={`${styles.button} ${styles[color]} ${disabled ? styles.disabled : ''}`}
        disabled={disabled}
        onClick={onClick}
        style={style}
      >
        {title}
      </button>
    );
  }
}

Button.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  style: {},
  index: null,
  color: 'white',
  disabled: false
};

export default Button;
