import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './Input.module.scss';

const customStyle = {
  errorState: {
    borderColor: 'rgba(252, 88, 78, 0.6)',
    backgroundColor: 'rgba(252, 88, 78, 0.1)'
  }
};
class Input extends PureComponent {
  render() {
    const {
      type,
      value,
      placeholder,
      label,
      labelStyle,
      error,
      name,
      style,
      containerStyle,
      onKeyPress,
      onChange,
      disabled
    } = this.props;
    const inputStyle = error ? { ...style, ...customStyle.errorState } : style;
    return (
      <div style={containerStyle} className={styles.container}>
        {label && (
          <p className={styles.label} style={labelStyle}>
            {label}
          </p>
        )}
        <input
          type={type}
          value={value}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
          onChange={onChange}
          className={styles.input}
          style={inputStyle}
        />
        {error && <p className={styles.errorText}>{error}</p>}
      </div>
    );
  }
}

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  index: PropTypes.number,
  error: PropTypes.string,
  label: PropTypes.any,
  labelStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  onKeyPress: PropTypes.func,
  disabled: PropTypes.bool
};

Input.defaultProps = {
  type: 'text',
  style: {},
  labelStyle: {},
  containerStyle: {},
  placeholder: '',
  error: undefined,
  label: undefined,
  disabled: false
};

export default Input;
