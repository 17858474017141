import React, { PureComponent } from 'react';
import Spinner from 'react-spinkit';

const customStyles = {
  container: {
    display: 'flex',
    flex: 1,
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center'
  },
  spinner: { width: 50, height: 50 }
};

class Loading extends PureComponent {
  render() {
    return (
      <div style={customStyles.container}>
        <Spinner fadeIn="none" name="circle" color="#4c6072" style={customStyles.spinner} />
      </div>
    );
  }
}

export default Loading;
