import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

const styles = {
  baseStyle: {
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 15
  },
  activeStyle: {
    borderStyle: 'solid',
    borderColor: '#6c6',
    backgroundColor: '#eee'
  },
  rejectStyle: {
    borderStyle: 'solid',
    borderColor: '#c66',
    backgroundColor: '#eee'
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#70869e'
  }
};

class FileDropper extends PureComponent {
  onDrop = acceptedFiles => {
    const { onFileDropped, type } = this.props;
    acceptedFiles.forEach(file => {
      onFileDropped(file, type);
    });
  };

  render() {
    const { accepts, file } = this.props;
    let fileName = `Upload PDF`;
    if (typeof file === 'object' && file !== null) {
      fileName = file.name;
    }
    if (typeof file === 'string') {
      fileName = `Upload New`;
    }
    return (
      <div>
        <Dropzone
          accept={`application/pdf, image/*`}
          onDrop={this.onDrop}
          multiple={false}
          name="dropzone"
        >
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
            let dropStyle = { ...styles.baseStyle };
            dropStyle = isDragActive ? { ...dropStyle, ...styles.activeStyle } : dropStyle;
            dropStyle = isDragReject ? { ...dropStyle, ...styles.rejectStyle } : dropStyle;
            return (
              <div
                {...getRootProps()}
                style={dropStyle}
                className={classNames('dropzone', {
                  'dropzone--isActive': isDragActive
                })}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop {`${accepts}`} here...</p>
                ) : (
                  // eslint-disable-next-line
                  <a style={styles.link} className="text-center">
                    {fileName}
                  </a>
                )}
              </div>
            );
          }}
        </Dropzone>
        {typeof file === 'string' && file !== null && (
          <a style={styles.link} href={file} target="_blank" rel="noopener noreferrer">
            View in New Tab
          </a>
        )}
      </div>
    );
  }
}

FileDropper.propTypes = {
  file: PropTypes.any,
  accepts: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  onFileDropped: PropTypes.func.isRequired
};

export default FileDropper;
