import React, { PureComponent } from 'react';
import NavBar from '../../containers/NavBar/NavBar';
import styles from './Dashboard.module.scss';

class Dashboard extends PureComponent {
  render() {
    return (
      <div className={styles.container}>
        <NavBar />
        <h1>Dashboard</h1>
      </div>
    );
  }
}

export default Dashboard;
