import React, { PureComponent } from 'react';
import styles from './Inbox.module.scss';
import NavBar from '../../containers/NavBar/NavBar';
import CaseListings from '../../containers/CaseListings/CaseListings';

class Inbox extends PureComponent {
  state = {
    user: {
      accountType: 'super'
    }
  };

  render() {
    const { user } = this.state;
    return (
      <div className={styles.all}>
        <NavBar user={user} />
        <CaseListings user={user} />
      </div>
    );
  }
}

export default Inbox;
