import * as ActionTypes from '../ActionTypes';

const initialState = {
  user: undefined,
  isFetching: true,
  error: undefined
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCHING_USER:
      return Object.assign({}, state, {
        user: undefined,
        isFetching: true,
        error: undefined
      });

    case ActionTypes.FETCHING_USER_SUCCESS:
      return Object.assign({}, state, {
        user: payload,
        isFetching: false,
        error: undefined
      });

    case ActionTypes.FETCHING_USER_FAIL:
      return Object.assign({}, state, {
        user: undefined,
        isFetching: false,
        error: payload
      });

    default:
      return state;
  }
};
