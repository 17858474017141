import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import styles from './NewCase.module.scss';
import NavBar from '../../containers/NavBar/NavBar';
import PatientForm from '../../containers/PatientForm/PatientForm';
import Cases from '../../redux/actions/Cases';

class NewCase extends PureComponent {
  state = {
    error: undefined,
    success: false,
    loading: false,
    redirect: false
  };

  addNewCase = async data => {
    this.setState({ success: false, error: undefined, loading: true });
    const uploadData = { ...data };
    try {
      if (uploadData.immunology_file !== null && typeof uploadData.immunology_file === 'object') {
        const foodImmunologyUrl = await Cases.uploadFile(uploadData.immunology_file);
        uploadData.immunology_file = foodImmunologyUrl.imageUrl;
      }

      if (uploadData.food_dosing_file !== null && typeof uploadData.food_dosing_file === 'object') {
        const foodDosingUrl = await Cases.uploadFile(uploadData.food_dosing_file);
        uploadData.food_dosing_file = foodDosingUrl.imageUrl;
      }

      if (uploadData.anaphylaxis_file !== null && typeof uploadData.anaphylaxis_file === 'object') {
        const anaphylaxisUrl = await Cases.uploadFile(uploadData.anaphylaxis_file);
        uploadData.anaphylaxis_file = anaphylaxisUrl.imageUrl;
      }

      const createResult = await Cases.createNewCase(uploadData);
      if (createResult) {
        console.log(createResult);
        this.setState({ success: true, error: undefined, loading: false });
        // Redirect user back to cases dashboard after 2 seconds
        setTimeout(() => this.setState({ redirect: true }), 2000);
      }
    } catch (error) {
      this.setState({ success: false, error: error.message, loading: false });
      console.log(error);
    }
  };

  render() {
    const { error, success, loading, redirect } = this.state;

    if (redirect) {
      return <Redirect to="/cases/all" />;
    }
    return (
      <div className={styles.all}>
        <NavBar />
        <PatientForm onSubmit={this.addNewCase} success={success} error={error} loading={loading} />
      </div>
    );
  }
}

export default NewCase;
