import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from './config/Bugsnag';
import App from './App';
import * as serviceWorker from './serviceWorker';
// CSS imports
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import 'react-table/react-table.css';

const ErrorBoundary = Bugsnag.bugsnagClient.getPlugin('react');

const noop = () => {};
if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
  console.error = noop;
}

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
