import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './AuthForm.scss';
import Spinner from 'react-spinkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import CompanyLogo from '../../assets/scfai-logo.jpg';
import PatientLogo from '../../assets/scfai-portal-logo.png';
import Button from '../../components/Button/Button';
import ErrorBox from '../../components/ErrorBox/ErrorBox';

const customStyles = {
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center'
  }
};
class AuthForm extends PureComponent {
  render() {
    const { onSubmit, error, children, submitLabel, loading } = this.props;
    return (
      <div className="auth-form-container">
        <form>
          <img className="company-logo" src={CompanyLogo} alt="SCFAI Logo" />
          <img className="portal-logo" src={PatientLogo} alt="Patient Portal" />
          {children}
          {error && <ErrorBox error={error.message} />}
          <div style={customStyles.buttonContainer}>
            {loading ? (
              <Spinner fadeIn="none" name="circle" color="#4c6072" />
            ) : (
              <Button title={submitLabel} onClick={onSubmit} disabled={loading} />
            )}
          </div>
        </form>
        {submitLabel === 'Log In' && (
          // only show in login page
          <div className="backContainer">
            <FontAwesomeIcon icon={faChevronLeft} size="sm" color="#47c3d4" />
            <a href="https://socalfoodallergy.org" className="btn btn-link btn-back">
              Back to socalfoodallergy.org
            </a>
          </div>
        )}
      </div>
    );
  }
}

AuthForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool,
  submitLabel: PropTypes.string,
  children: PropTypes.any
};

export default AuthForm;
