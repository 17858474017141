import React, { PureComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import styles from './NavMenu.module.scss';
import User from '../../redux/actions/User';

class NavMenu extends PureComponent {
  signOut = () => {
    User.signOut();
  };

  render() {
    const { auth } = this.props;
    const windowLocation = window.location.href;
    const isPatient = auth.user.role === 'patient';
    const isSuper = auth.user.role === 'super';
    const isAdmin = auth.user.role === 'admin';

    if (!auth.user) {
      return <Redirect to="/login" />;
    }

    let name = `${auth.user.first_name} ${auth.user.last_name}`;
    if (isAdmin || isSuper) {
      name = auth.user.role;
    }
    return (
      <div className={styles.container}>
        {!isPatient && (
          <div className={styles.leftNav}>
            {isAdmin && (
              <div>
                <Link
                  to="/cases/all"
                  className={
                    windowLocation.includes('/cases/all') ? styles.activeNavItem : styles.navItem
                  }
                >
                  Cases
                </Link>
                <Link
                  to="/cases/new"
                  className={
                    windowLocation.includes('/cases/new') ? styles.activeNavItem : styles.navItem
                  }
                >
                  New Case
                </Link>
              </div>
            )}
            {isSuper && (
              <Link
                to="/cases/inbox"
                className={
                  windowLocation.includes('/cases/inbox') ? styles.activeNavItem : styles.navItem
                }
              >
                Inbox
              </Link>
            )}
          </div>
        )}

        <div className={styles.rightNav}>
          <div className={styles.user}>
            <FontAwesomeIcon icon={faUser} size="sm" color="#777" />
            <p>{name}</p>
          </div>

          <Link to={{}} onClick={this.signOut} className={styles.navItem}>
            Log Out
          </Link>
        </div>
      </div>
    );
  }
}

NavMenu.propTypes = {
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(NavMenu);
