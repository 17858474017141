/**
 * USER ACTIONS
 */
export const FETCHING_USER = 'FETCHING_USER';
export const FETCHING_USER_SUCCESS = 'FETCHING_USER_SUCCESS';
export const FETCHING_USER_FAIL = 'FETCHING_USER_FAIL';

export const UPDATING_USER = 'UPDATING_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const LOGOUT_USER = 'LOGOUT_USER';

export const FETCHING_CASES = 'FETCHING_CASES';
export const FETCHING_CASES_SUCCESS = 'FETCHING_CASES_SUCCESS';
export const FETCHING_CASES_FAIL = 'FETCHING_CASES_FAIL';
