import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import CasesReducer from './CasesReducer';

const AppReducer = combineReducers({
  auth: AuthReducer,
  cases: CasesReducer
});

const rootReducer = (state, action) => {
  return AppReducer(state, action);
};

export default rootReducer;
