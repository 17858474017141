import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';

let middleWare;

if (process.env.NODE_ENV === 'development') {
  middleWare = applyMiddleware(thunk, createLogger());
} else {
  middleWare = applyMiddleware(thunk);
}
// mount it on the Store
const Store = createStore(rootReducer, compose(middleWare));

export default Store;
