import { API_URL, getToken } from '../../config/Api';
import * as ActionTypes from '../ActionTypes';
import store from '../Store';

const getAllCases = () => {
  return async dispatch => {
    dispatch({ type: ActionTypes.FETCHING_CASES });
    const settings = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    };

    try {
      const response = await fetch(`${API_URL}/case/all`, settings);
      console.log(response);
      if (response.ok) {
        const responseJson = await response.json();
        dispatch({ type: ActionTypes.FETCHING_CASES_SUCCESS, payload: responseJson });
        return responseJson;
      }
      throw Error(`Failed to Fetch Cases`);
    } catch (error) {
      dispatch({ type: ActionTypes.FETCHING_CASES_FAIL, payload: error });
      throw error;
    }
  };
};

const uploadFile = async file => {
  const form = new FormData();
  form.append('file', file);

  const settings = {
    headers: {
      Authorization: `Bearer ${getToken()}`
    },
    method: 'POST',
    body: form
  };

  try {
    const response = await fetch(`${API_URL}/upload`, settings);
    const responseJson = response.json();
    if (response.ok) {
      return responseJson;
    }

    throw Error(responseJson);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const createNewCase = async data => {
  const settings = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch(`${API_URL}/case`, settings);
    if (response.ok) {
      const responseJson = await response.json();
      await store.dispatch(getAllCases());
      return responseJson;
    }
    throw Error(`Error Creating New Case`);
  } catch (error) {
    throw error;
  }
};

const updateCaseById = async data => {
  const settings = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch(`${API_URL}/case`, settings);
    console.log(response);
    if (response.ok) {
      const responseJson = await response.json();
      await store.dispatch(getAllCases());
      return responseJson;
    }
    throw Error(`Failed to Update Case`);
  } catch (error) {
    throw error;
  }
};

const getCaseByUserId = async id => {
  const settings = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`
    }
  };

  try {
    const response = await fetch(`${API_URL}/case/user/${id}`, settings);
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    }
    throw Error(`Failed to Fetch Case`);
  } catch (error) {
    throw Error(`Failed to Fetch Case`);
  }
};

const invitePatient = async data => {
  const settings = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`
    },
    method: 'POST',
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch(`${API_URL}/user/invite`, settings);
    if (response.ok) {
      await store.dispatch(getAllCases());
      return response;
    }

    throw Error('An error occurred trying to invite this patient.');
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export default {
  createNewCase,
  getAllCases,
  getCaseByUserId,
  updateCaseById,
  uploadFile,
  invitePatient
};
