import React, { PureComponent } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Tab, Tabs, TabList } from 'react-tabs';
import styles from './CaseListings.module.scss';
import Cases from '../../redux/actions/Cases';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import 'react-tabs/style/react-tabs.css';

const customStyles = {
  searchContainer: {
    marginBottom: 0,
    alignSelf: 'flex-end'
  },
  inviteResend: {
    padding: `5px 10px`
  }
};
class CaseListings extends PureComponent {
  state = {
    allCases: this.props.cases.data,
    search: '',
    filteredCases: this.props.cases.data,
    caseSelected: null,
    tabIndex: 0
  };

  componentDidMount() {
    const { cases } = this.props;
    // Initial Load
    if (cases.data.length === 0) {
      this.getAllCases();
    }
  }

  componentDidUpdate(prevProps) {
    const { search, tabIndex } = this.state;
    if (prevProps.cases.isFetching && !this.props.cases.isFetching) {
      // If User is searching for a specific user, keep table on that user
      if (search.length > 0) {
        this.setState({ allCases: this.props.cases.data }, () =>
          this.onChangeSearch({ target: { value: search } })
        );

        return;
      }
      // If User is a different tab from 'All', update data keeping tab index
      if (tabIndex !== 0) {
        this.setState({ allCases: this.props.cases.data }, () => this.onTabSelect(tabIndex));
        return;
      }

      this.setState({ allCases: this.props.cases.data, filteredCases: this.props.cases.data });
    }
  }

  onChangeSearch = e => {
    const { value } = e.target;
    const { allCases, tabIndex } = this.state;

    if (tabIndex !== 0) {
      this.setState({ tabIndex: 0 });
    }
    const filteredCases = allCases.filter(obj => {
      const caseName = `${obj.first_name} ${obj.last_name}`;
      const medicalCaseNo = obj.medical_case_no || '';
      if (
        caseName.toLowerCase().includes(value.toLowerCase()) ||
        medicalCaseNo.toLowerCase().includes(value.toLowerCase())
      ) {
        return obj;
      }
      return undefined;
    });

    this.setState({
      search: value,
      filteredCases
    });
  };

  /**
   * Called on CDM for initial load of cases, otherwise
   * cases come from redux state. Updated accordingly
   * when creating new/updating to refresh list in redux store.
   */
  getAllCases = async () => {
    const { getAllCases } = this.props;
    try {
      const casesResult = await getAllCases();
      if (casesResult) {
        this.setState({ allCases: casesResult, filteredCases: casesResult });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onClickRow = rowInfo => {
    const { original: rowData } = rowInfo;
    console.log(rowData);
    this.setState({ caseSelected: rowData });
  };

  invitePatient = async row => {
    const data = {
      id: row.user_id,
      name: `${row.first_name} ${row.last_name}`,
      email: row.email
    };

    console.log(data);
    try {
      const result = await Cases.invitePatient(data);
      if (result) {
        /**
         * Show User Invited Successfully & Resend Button on success.
         */
        console.log('User invited successfully');
      }
    } catch (error) {
      console.log(error);
    }
  };

  onTabSelect = index => {
    const { allCases } = this.state;

    this.setState({ tabIndex: index });

    let status = 'ALL';
    switch (index) {
      case 0:
        status = 'ALL';
        break;
      case 1:
        status = 'WAITING_FOR_APPROVAL';
        break;
      case 2:
        status = 'APPROVED';
        break;
      case 3:
        status = 'REMISSION';
        break;
      default:
        break;
    }
    if (status === 'ALL') {
      this.setState({ filteredCases: allCases });
      return;
    }

    const filteredCases = allCases.filter(obj => {
      return obj.status === status;
    });

    this.setState({ filteredCases });
  };

  formatStatus = status => {
    if (status === 'WAITING_FOR_APPROVAL') {
      return 'Waiting For Approval';
    }

    return `${status[0]}${status.slice(1).toLowerCase()}`;
  };

  render() {
    const { auth, cases } = this.props;
    const { filteredCases, search, caseSelected } = this.state;

    if (caseSelected) {
      return <Redirect push to={{ pathname: '/cases/update', state: { caseSelected } }} />;
    }

    const cols = [
      {
        id: 'medicalCaseNo',
        Header: 'Medical Case #',
        accessor: 'medical_case_no'
      },
      {
        id: 'patientName',
        Header: 'Patient Name',
        accessor: d => `${d.first_name} ${d.last_name}`
      },
      {
        id: 'gender',
        Header: 'Gender',
        accessor: d => `${d.gender[0].toUpperCase()}${d.gender.slice(1)}`,
        width: 120
      },
      {
        id: 'dateOfBirth',
        Header: 'Date of Birth',
        accessor: d =>
          `${moment(d.date_of_birth)
            .utcOffset(0)
            .format('MM/DD/YYYY')}`,
        width: 120
      },
      {
        id: 'status',
        Header: 'Status',
        accessor: d => this.formatStatus(d.status)
      }
    ];

    if (auth.user.role === 'admin') {
      cols.push({
        Header: 'Invite',
        Cell: rowInfo => {
          const { original: row } = rowInfo;
          if (row.status === 'APPROVED') {
            if (row.invite_status === 'NOT_SENT') {
              return (
                <Button
                  title="Invite"
                  style={customStyles.inviteResend}
                  onClick={() => this.invitePatient(row)}
                />
              );
            }

            if (row.invite_status === 'SENT') {
              return (
                <Button
                  title="Resend"
                  style={customStyles.inviteResend}
                  onClick={() => this.invitePatient(row)}
                />
              );
            }

            if (row.invite_status === 'ACCEPTED') {
              return 'Accepted';
            }
          }
          return null;
        }
      });
    }
    return (
      <div className="container">
        <div className={styles.header}>
          <h1>Case Listings</h1>
        </div>
        <div className={styles.searchHeader}>
          <Input
            value={search}
            name="search"
            placeholder="Search for patient"
            containerStyle={customStyles.searchContainer}
            onChange={this.onChangeSearch}
          />
        </div>
        <div className={styles.contentContainer}>
          <Tabs selectedIndex={this.state.tabIndex} onSelect={this.onTabSelect}>
            <TabList style={{ display: 'flex' }}>
              <Tab>All</Tab>
              <Tab>Waiting For Approval</Tab>
              <Tab>Approved</Tab>
              <Tab>Remission</Tab>
            </TabList>
          </Tabs>
          <ReactTable
            data={filteredCases}
            loading={cases.isFetching}
            loadingText={'Loading Cases...'}
            noDataText={'No Patients Found! Try adding some.'}
            defaultPageSize={10}
            showPageSizeOptions={true}
            defaultSorted={[
              {
                id: 'patientName',
                desc: false
              }
            ]}
            columns={cols}
            className="-striped -highlight"
            getTdProps={(state, rowInfo, column) => {
              return {
                onClick: () => {
                  if (column.Header !== 'Invite') {
                    this.onClickRow(rowInfo);
                  }
                }
              };
            }}
          />
        </div>
      </div>
    );
  }
}

CaseListings.propTypes = {
  auth: PropTypes.object,
  cases: PropTypes.object,
  getAllCases: PropTypes.func
};

const mapStateToProps = state => ({
  auth: state.auth,
  cases: state.cases
});

const mapDispatchToProps = dispatch => {
  return {
    getAllCases: () => dispatch(Cases.getAllCases())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseListings);
