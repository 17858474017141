import { API_URL, setToken, getToken, removeToken } from '../../config/Api';
import * as ActionTypes from '../ActionTypes';
import store from '../Store';

const loginUser = async data => {
  const settings = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch(`${API_URL}/user/login`, settings);
    const responseJson = await response.json();
    console.log(responseJson);
    if (response.ok) {
      setToken(responseJson.id_token);
      return responseJson;
    }

    throw Error(responseJson.message || responseJson.error_description);
  } catch (error) {
    throw error;
  }
};

const getUser = () => {
  return async dispatch => {
    dispatch({ type: ActionTypes.FETCHING_USER });
    const settings = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`
      }
    };

    try {
      const response = await fetch(`${API_URL}/user/`, settings);
      console.log(response);
      if (response.ok) {
        const responseJson = await response.json();
        dispatch({ type: ActionTypes.FETCHING_USER_SUCCESS, payload: responseJson });
        return responseJson;
      }
      throw Error(`${response.status} ${response.statusText}`);
    } catch (error) {
      dispatch({ type: ActionTypes.FETCHING_USER_FAIL, payload: error });
      throw error;
    }
  };
};

const getUserById = async id => {
  const settings = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`
    }
  };

  try {
    const response = await fetch(`${API_URL}/user/${id}`, settings);
    console.log(response);
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    }
    throw Error(`Failed to Fetch Case`);
  } catch (error) {
    throw error;
  }
};

const updateUserById = async (id, data) => {
  const settings = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`
    },
    body: JSON.stringify(data)
  };

  try {
    const response = await fetch(`${API_URL}/user/update/${id}`, settings);
    console.log(response);
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    }
    throw Error(`Failed to Update User`);
  } catch (error) {
    throw error;
  }
};

const checkAuth = async () => {
  const token = getToken();
  if (token === null) {
    // router redirects to login as user in redux state is undefined
    store.dispatch({
      type: ActionTypes.FETCHING_USER_FAIL,
      payload: { message: 'No Token Found' }
    });
    return;
  }

  try {
    await store.dispatch(getUser());
  } catch (error) {
    console.log(error.message);
  }
};

const signOut = async () => {
  try {
    removeToken();
    checkAuth();
  } catch (error) {
    console.log(error);
  }
};

const forgotPassword = async data => {
  const settings = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };

  try {
    return await fetch(`${API_URL}/user/forgot_password`, settings);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export default {
  loginUser,
  getUser,
  checkAuth,
  signOut,
  getUserById,
  updateUserById,
  forgotPassword
};
