import React, { PureComponent } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  // Dashboard,
  Login,
  ForgotPassword,
  NewCase,
  PatientDashboard,
  Cases,
  Inbox,
  UpdatePatientCase
} from '../pages';
import AuthedRoute from './AuthedRoute';

const onlyPatient = ['patient'];
const onlyAdmin = ['admin'];
const onlySuper = ['super'];
const superAndAdmin = ['admin', 'super'];
class Router extends PureComponent {
  render() {
    const { auth } = this.props;
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/forgot-password" name="Forgot Password" component={ForgotPassword} />
          <Route
            exact
            path="/forgot-password/:email"
            name="Forgot Password"
            component={props => <ForgotPassword {...props} />}
          />
          <Route
            exact
            path="/"
            render={() => {
              if (!auth.user) {
                return <Redirect to="/login" />;
              }
              if (auth.user.role === 'super') {
                return <Redirect to="/cases/inbox" />;
              }
              if (auth.user.role === 'patient') {
                return <Redirect to="/patient/dashboard" />;
              }
              return <Redirect to="/cases/all" />;
            }}
          />
          {/* <AuthedRoute auth={auth} exact path="/dashboard" name="Dashboard" component={Dashboard} /> */}
          <AuthedRoute
            auth={auth}
            authorizedRoles={onlyAdmin}
            exact
            path="/cases/all"
            name="Case Listings"
            component={Cases}
          />
          <AuthedRoute
            auth={auth}
            authorizedRoles={onlySuper}
            exact
            path="/cases/inbox"
            name="Case Listings Inbox"
            component={Inbox}
          />
          <AuthedRoute
            auth={auth}
            authorizedRoles={onlyAdmin}
            exact
            path="/cases/new"
            name="New Cases"
            component={NewCase}
          />
          <AuthedRoute
            auth={auth}
            exact
            authorizedRoles={superAndAdmin}
            path="/cases/update"
            name="Update Case"
            component={UpdatePatientCase}
          />
          <AuthedRoute
            auth={auth}
            exact
            authorizedRoles={onlyPatient}
            path="/patient/dashboard"
            name="Patient Dashboard"
            component={PatientDashboard}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Router);
