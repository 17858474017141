import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const style = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    margin: 20,
    borderRadius: 4,
    color: 'white',
    fontWeight: 'bold'
  },
  text: {
    margin: 0,
    color: 'white'
  }
};
class ErrorBox extends PureComponent {
  render() {
    const { error } = this.props;
    return (
      <div style={style.container}>
        <p style={style.text}>{error}</p>
      </div>
    );
  }
}

ErrorBox.propTypes = {
  error: PropTypes.string
};

export default ErrorBox;
