import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AuthForm from '../../containers/AuthForm/AuthForm';
import Input from '../../components/Input/Input';
import User from '../../redux/actions/User';

class ForgotPassword extends PureComponent {
  state = {
    email: this.props.match.params.email || '',
    loading: false,
    error: undefined,
    showSuccess: false
  };

  signUp = async e => {
    this.setState({ loading: true });
    e.preventDefault();
    const { email } = this.state;
    const body = {
      email
    };
    try {
      const result = await User.forgotPassword(body);
      if (result) {
        this.setState({ showSuccess: true, loading: false });
      } else {
        throw Error('Error occurred sending email instructions');
      }
    } catch (error) {
      this.setState({ error, loading: false });
      console.log(error);
    }
    // If successful show checkmark
  };

  onChangeText = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onKeyPress = e => {
    if (e.key === 'Enter') {
      this.signUp();
    }
  };

  render() {
    const { email, error, loading, showSuccess } = this.state;
    return (
      <AuthForm onSubmit={this.signUp} submitLabel="Sign Up" loading={loading} error={error}>
        <p className="description text-center">
          In order to reset your password, please enter your email and we will send you further
          instructions.
        </p>
        <Input
          type="email"
          onChange={this.onChangeText}
          value={email}
          name="email"
          handleKeyPress={this.onKeyPress}
          disabled={this.props.match.params.email !== null}
          placeholder="Enter your email"
        />
        {showSuccess && (
          <p className="description text-center">
            Success! Please check your email for more instructions.
          </p>
        )}
      </AuthForm>
    );
  }
}

ForgotPassword.propTypes = {
  match: PropTypes.object
};

export default ForgotPassword;
