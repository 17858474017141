const API_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.REACT_APP_API_URL;
const getToken = () => {
  return localStorage.getItem('id_token') || null;
};

const setToken = idToken => {
  localStorage.setItem('id_token', idToken);
};

const removeToken = () => {
  localStorage.removeItem('id_token');
};

export { API_URL, getToken, setToken, removeToken };
