import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import styles from './NavBar.module.scss';
import CompanyLogo from '../../assets/scfai-logo.jpg';
import PatientLogo from '../../assets/scfai-portal-logo.png';
import NavMenu from './NavMenu';

class NavBar extends Component {
  state = {
    showMenu: true
  };

  toggleMenu = () => {
    const { showMenu } = this.state;
    this.setState({ showMenu: !showMenu });
  };

  logout = () => {};

  render() {
    const { showMenu } = this.state;
    return (
      <nav>
        <div className="container">
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.navbarBrand}>
                <div className={styles.companyLogo}>
                  <img src={CompanyLogo} alt="SCFAI Logo" />
                </div>
                <div className={styles.portalLogo}>
                  <img src={PatientLogo} alt="SCFAI Portal Logo" />
                </div>
              </div>
              <div className={styles.navBarToggle} onClick={this.toggleMenu}>
                <FontAwesomeIcon icon={faBars} size="2x" color="#777" />
              </div>
            </div>
            {showMenu && <NavMenu showMenu={showMenu} />}
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
