import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthForm from '../../containers/AuthForm/AuthForm';
import Input from '../../components/Input/Input';
import User from '../../redux/actions/User';

class Login extends PureComponent {
  state = {
    email: '',
    password: '',
    mfaCode: '',
    error: undefined,
    loading: false
  };

  login = async e => {
    this.setState({ loading: true });
    const { getUser } = this.props;
    e.preventDefault();
    const { email, password } = this.state;

    const data = {
      email,
      password
    };

    try {
      const result = await User.loginUser(data);
      this.setState({ loading: false });
      if (result) {
        console.log(result);
        await getUser();
      }
    } catch (error) {
      this.setState({ error, loading: false });
      console.log(error.message);
    }
  };

  onChangeText = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onKeyPress = e => {
    if (e.key === 'Enter') {
      this.login();
    }
  };

  render() {
    const { email, password, mfaCode, loading, error } = this.state;
    const { mfaRequired, auth } = this.props;

    if (auth.user) {
      return <Redirect to="/" />;
    }

    return (
      <AuthForm
        onSubmit={this.login}
        error={error}
        loading={auth.isFetching || loading}
        submitLabel="Log In"
      >
        <p className="description text-center">
          Log in with the email you assigned when registering for the Tolerance Induction Program.
          {/* If you are not signed up to TIP, please feel free to sign up for the waiting list <a href="https://app.waitwhile.com/checkin/socalfoodallergy" target="_blank">here</a>. */}
        </p>
        <Input
          type="text"
          onChange={this.onChangeText}
          value={email}
          name="email"
          placeholder="Email Address"
        />

        <Input
          type="password"
          onChange={this.onChangeText}
          value={password}
          name="password"
          handleKeyPress={this.onKeyPress}
          placeholder="Password"
        />

        {mfaRequired && (
          <Input
            type="number"
            onChange={this.onChangeText}
            placeholder="000000"
            value={mfaCode}
            name="mfaCode"
            label="MFA Code"
          />
        )}
      </AuthForm>
    );
  }
}

Login.propTypes = {
  mfaRequired: PropTypes.bool,
  auth: PropTypes.object,
  getUser: PropTypes.func
};

Login.defaultProps = {
  mfaRequired: false
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => {
  return {
    getUser: () => dispatch(User.getUser())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
