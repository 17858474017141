import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from '../components/Loading/Loading';

const AuthedRoute = ({ component: Component, auth, authorizedRoles, ...rest }) => {
  const { user, isFetching } = auth;

  if (isFetching) {
    return <Loading />;
  }
  if (!user) {
    return <Route {...rest} render={() => <Redirect to="/login" />} />;
  }

  if (authorizedRoles.indexOf(user.role) < 0) {
    return <Route {...rest} render={() => <Redirect to="/" />} />;
  }

  return <Route {...rest} render={props => <Component {...props} user={user} />} />;
};

AuthedRoute.propTypes = {
  component: PropTypes.any,
  auth: PropTypes.object,
  authorizedRoles: PropTypes.array
};

export default AuthedRoute;
