import * as ActionTypes from '../ActionTypes';

const initialState = {
  data: [],
  isFetching: true,
  error: undefined
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCHING_CASES:
      return Object.assign({}, state, {
        isFetching: true,
        error: undefined
      });

    case ActionTypes.FETCHING_CASES_SUCCESS:
      return Object.assign({}, state, {
        data: payload,
        isFetching: false,
        error: undefined
      });

    case ActionTypes.FETCHING_CASES_FAIL:
      return Object.assign({}, state, {
        data: [],
        isFetching: false,
        error: payload
      });

    default:
      return state;
  }
};
