import React, { PureComponent } from 'react';
import styles from './Cases.module.scss';
import NavBar from '../../containers/NavBar/NavBar';
import CaseListings from '../../containers/CaseListings/CaseListings';

class Cases extends PureComponent {
  render() {
    return (
      <div className={styles.all}>
        <NavBar />
        <CaseListings />
      </div>
    );
  }
}

export default Cases;
