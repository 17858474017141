import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Router from './router/Router';
import Store from './redux/Store';
import User from './redux/actions/User';

class App extends Component {
  componentDidMount = () => {
    console.log('App CDM');
    User.checkAuth();
  };

  render() {
    return (
      <Provider store={Store}>
        <Router />
      </Provider>
    );
  }
}

export default App;
