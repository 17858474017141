import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './PatientDashboard.module.scss';
import NavBar from '../../containers/NavBar/NavBar';
import Button from '../../components/Button/Button';
import Cases from '../../redux/actions/Cases';
import ErrorBox from '../../components/ErrorBox/ErrorBox';

const customStyles = {
  button: {
    marginRight: 10,
    marginBottom: 10
  }
};
class PatientDashboard extends PureComponent {
  state = {
    userCase: {},
    error: undefined
  };

  componentDidMount() {
    const { user } = this.props.auth;
    // Get users case information, therapy_plan & all files
    this.getCaseInformation(user.id);
  }

  getCaseInformation = async id => {
    try {
      const caseResult = await Cases.getCaseByUserId(id);
      if (caseResult) {
        this.setState({ userCase: caseResult, error: undefined });
      }
    } catch (error) {
      console.log(error);
      this.setState({ error: error.message });
    }
  };

  onClickFoodImmunology = () => {
    const { userCase } = this.state;
    window.open(userCase.immunology_file, '_blank');
  };

  onClickAnaphylaxisPlan = () => {
    const { userCase } = this.state;
    window.open(userCase.anaphylaxis_file, '_blank');
  };

  onClickFoodDosingStrategy = () => {
    const { userCase } = this.state;
    window.open(userCase.food_dosing_file, '_blank');
  };

  render() {
    const { user } = this.props.auth;
    const { userCase, error } = this.state;
    return (
      <div className={styles.all}>
        <NavBar />
        <div className="container">
          <div className="page-header">
            <h2>{`Welcome ${user.first_name} ${
              user.middle_name ? `${user.middle_name} ${user.last_name}` : user.last_name
            }!`}</h2>
          </div>

          {error && <ErrorBox error={error} />}

          <div className={styles.planContainer}>
            <div className={styles.leftContainer}>
              <p>FOOD THERAPY PLAN</p>
            </div>
            <div className={styles.rightContainer}>
              <p>{userCase.therapy_plan}</p>
            </div>
          </div>
          <div className={styles.fileContainer}>
            <div className={styles.leftContainer}>
              <p>PDF FILES</p>
            </div>
            <div className={styles.fileContentContainer}>
              <Button
                title="Food Immunology Snapshot"
                style={customStyles.button}
                disabled={!userCase.immunology_file}
                onClick={this.onClickFoodImmunology}
              />
              <Button
                title="Anaphylaxis Plan"
                style={customStyles.button}
                disabled={!userCase.anaphylaxis_file}
                onClick={this.onClickAnaphylaxisPlan}
              />
              <Button
                title="Food Dosing Strategy"
                style={customStyles.button}
                disabled={!userCase.food_dosing_file}
                onClick={this.onClickFoodDosingStrategy}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PatientDashboard.propTypes = {
  auth: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PatientDashboard);
